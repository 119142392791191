import React from 'react';

import VehicleDisplay from './vehicle-display';
import './style.css';

const DEFAULT_STATE = {
  accountId: null,
  isWidgetActive: true,
  vehicles: null
};

class WidgetPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = DEFAULT_STATE;

    this.refresh = this.refresh.bind(this);
    this.handleTrellisSuccess = this.handleTrellisSuccess.bind(this);
    this.handleTrellisFailure = this.handleTrellisFailure.bind(this);
    this.handleTrellisClose = this.handleTrellisClose.bind(this);
    this.fetchPolicyThroughMiddleware = this.fetchPolicyThroughMiddleware.bind(
      this
    );
    this.fetchPolicy = this.fetchPolicy.bind(this);

    this.handler = window.TrellisConnect.configure({
      client_id: process.env.REACT_APP_CLIENT_ID,
      features: 'nostickystate',

      onSuccess: this.handleTrellisSuccess,
      onFailure: this.handleTrellisFailure,
      onClose: this.handleTrellisClose,

      // A URL that is called asynchronously by the Trellis API when it has completed
      // pulling insurance data.
      webhook: 'https://api.myserver.com/trellisUpdate'
    });
  }

  handleTrellisSuccess(response) {
    // Successfully retrieved the accountID;
    this.setState({ accountId: response });

    // Retrieve account ID
    // If middleware server is defined use it, if not fetch directly using Trellis API
    if (process.env.REACT_APP_MIDDLEWARE_API_BASE)
      this.fetchPolicyThroughMiddleware(response);
    else this.fetchPolicy(response);
  }

  fetchPolicy(accountId) {
    const apiBase = process.env.REACT_APP_TRELLIS_API_BASE;

    const headers = {
      Accept: 'application/json',
      'X-API-Client-Id': process.env.REACT_APP_CLIENT_ID,
      'X-API-Secret-Key': process.env.REACT_APP_SECRET_KEY
    };

    fetch(`${apiBase}/account/${accountId}/policies`, {
      method: 'GET',
      headers: headers
    })
      .then(res => {
        return res.json(res);
      })
      .then(body => {
        if (body.policies) {
          const policy = body.policies[0];
          const vehicles = this.fromApiToModel(policy);

          this.setState({ vehicles });
        } else if (body.message) {
          alert(body.message);
        } else {
          alert('Unable to fetch policy');
        }
      });
  }

  /*
   * This function depends on middleware server which contains the CLIENT_ID and SECRET_KEY
   * so those keys doesn't get exposed in the browser. (Optional)
   */
  fetchPolicyThroughMiddleware(accountId) {
    const apiBase = process.env.REACT_APP_MIDDLEWARE_API_BASE;
    fetch(`${apiBase}/searchVin/${accountId}`)
      .then(res => {
        return res.json();
      })
      .then(response => {
        const vehicles = this.fromApiToModel(response);

        this.setState({ vehicles });
      });
  }

  fromApiToModel(rawData) {
    return rawData.vehicles
      ? rawData.vehicles.map(v => {
          const { make, model, vin, year } = v;
          return {
            make,
            model,
            vin,
            year
          };
        })
      : [];
  }

  handleTrellisFailure() {
    // DO NOTHING. Happy Path Only for this demo :)
  }

  handleTrellisClose() {
    this.setState({ isWidgetActive: false });
  }

  refresh() {
    // Reset the page back to the original state
    this.setState(DEFAULT_STATE);
    this.handler.open();
  }

  componentDidMount() {
    // Open Trellis Widget when the page is mounted
    this.handler.open();
  }

  render() {
    const { vehicles, isWidgetActive } = this.state;

    // Don't display anything while Widget is active
    if (isWidgetActive) return null;

    const LaunchButton = (
      <button className="btn btn-primary" onClick={this.refresh}>
        Open Trellis Widget
      </button>
    );

    /*
     * When Widget is Inactive (dismissed) then display either Vehicles Info
     * if not just a button that relaunches the Widget
     */
    if (!isWidgetActive) {
      if (vehicles) {
        return (
          <div className="widget-page">
            <h2>My Vehicles</h2>
            {vehicles.map((vehicle, i) => (
              <VehicleDisplay key={i} vehicle={vehicle} />
            ))}
          </div>
        );
      }

      return <div className="widget-page">{LaunchButton}</div>;
    }
  }
}

export default WidgetPage;
