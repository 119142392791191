import React from 'react';

import { VehicleModel } from '../../../models';

function VehicleDisplay({ vehicle }) {
  const { make, model, year, vin } = vehicle;

  return (
    <div className="vehicle-display">
      <div className="label">{`${year} ${make} ${model}`}</div>
      <div className="vin">VIN: {vin}</div>
    </div>
  );
}

VehicleDisplay.propTypes = VehicleModel;

export default VehicleDisplay;
