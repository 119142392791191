import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';

function LandingPage() {
  const ButtonLabel = "Let's Go";
  return (
    <div className="landing-page">
      <h1>Lookup Your VIN!</h1>
      <h2>You may need your VIN for</h2>
      <ul>
        <li>Scheduling maintenance</li>
        <li>Paying a parking ticket</li>
        <li>Getting a quote for auto insurance</li>
        <li>Listing their car for sale</li>
        <li>and more...</li>
      </ul>
      <Link to={'/widget?t=1'}>
        <button className="btn btn-primary btn-lg">{ButtonLabel}</button>
      </Link>
    </div>
  );
}

export default LandingPage;
