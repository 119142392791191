import React, { Fragment } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { LandingPage, WidgetPage } from './pages';

export const Router = () => (
  <BrowserRouter>
    <Fragment>
      <Switch>
        <Route path="/" component={LandingPage} exact={true} />
        <Route path="/widget" component={WidgetPage} />
        <Redirect to="/" />
      </Switch>
    </Fragment>
  </BrowserRouter>
);

export default Router;
